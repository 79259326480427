import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Header from "../components/Header";
import SocialGrid from "../components/SocialGrid";

export default function MeetupsEventsPage({ data }) {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  return (
    <main>
      <SEO
        title={`${frontmatter.seotitle ? frontmatter.seotitle : frontmatter.title} | Inland Northwest Technologists`}
        keywords={[`Inland Northwest Technologists`, `application`, `react`]}
        description={frontmatter.seodescription}
      />
      <Header {...frontmatter} />
      <SocialGrid />
    </main>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        subtitle
        banner {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
