import React from "react";
import Helmet from "react-helmet";

class SocialGrid extends React.Component {
  constructor(props) {
    super(props);
    this.intervalCheck = null;
    this.widget = null;
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.onload = () => this.tryCurator();
    script.src = "https://cdn.curator.io/3.1/js/curator.min.js";

    document.head.appendChild(script);
  }

  tryCurator() {
    if (this.intervalCheck) return;

    this.intervalCheck = setInterval(() => {
      if (window.Curator) {
        clearInterval(this.intervalCheck);

        this.widget = new window.Curator.Widgets.Waterfall({
          debug: false, // While you're testing
          container: "#curator-feed-facebook",
          feedId: "b494747e-c9ed-49ab-99e4-cb30b2310e4b",
        });
      }
    }, 100);
  }

  componentWillUnmount() {
    if (this.widget) {
      this.widget.destroy();
    }
  }

  render() {
    return (
      <article id="content">
        <Helmet>
          <link rel="stylesheet" type="text/css" href="https://cdn.curator.io/3.1/css/curator.min.css" lazyload />
        </Helmet>
        <div id="curator-feed-facebook">
          <a href="https://curator.io" target="_blank" className="crt-logo crt-tag" rel="noopener noreferrer">
            Powered by Curator.io
          </a>
        </div>
      </article>
    );
  }
}

export default SocialGrid;
